import React, { useState, useEffect } from 'react'
// import { FaBars } from 'react-icons/fa'
// import {Button} from './Button/Button'
import { Link } from 'react-router-dom'
import logoInnova from '../../Media/Img/logo7.png'
import { animateScroll as scroll } from 'react-scroll'
import {
    Nav, LogoLink, MenuIcon, CloseIcon, BarIcon,
    NavItem, NavLink, NavMenu, AngleDown, Button, NavLinkMobile, NavMenuResp,
    LogoImg
} from './NavbarElements'
import Dropdown from './Dropdown'
function Navbar() {
    const [scrollNav, setScrollNav] = useState(false);


    const [click, setClick] = useState(false)
    const [dropdown, setDropdown] = useState(false);


    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    const handleClick = () => setClick(!click);
    const closeMovileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false)
        } else {
            setDropdown(true)
        }
    }
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    const goInicio = () =>{
        toggleHome();
        closeMovileMenu();
    }
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])
    return (
        <>
            <Nav scrollNav={scrollNav}>
                <LogoLink to='/' onClick={toggleHome}>

                    <LogoImg src={logoInnova} />
                </LogoLink>
                <MenuIcon onClick={handleClick}>
                    {click ? (<CloseIcon />) : (<BarIcon />)}
                </MenuIcon>
               
                <NavMenu active={click}>
                    <NavItem
                    // to='/' 
                    // onClick={toggleHome}
                    >
                        <NavLink o='/' onClick={goInicio}>
                        INICIO
                        </NavLink>
                        
                    </NavItem>
                    <NavItem>
                        <NavLink
                            // to='/productos' onClick={closeMovileMenu}
                            onClick={closeMovileMenu}
                            to="productos"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                        >
                            PRODUCTOS
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                onClick={closeMovileMenu}
                            to="empresa" 
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                        >
                            EMPRESA
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink 
                        to='contacto' 
                        onClick={closeMovileMenu}
                        smooth={true}
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}
                            >
                            CONTACTO
                        </NavLink>
                    </NavItem>
                    <NavItem>
                      
                    </NavItem>
                </NavMenu>                
            </Nav>
        </>
    )
}

export default Navbar
