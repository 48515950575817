import React from 'react'

import {
    MainContainer, RightContacto, LeftContacto, TitleContacto, WrapperContacto, SubTitle,
    CardContacto, TextContacto, ContainerMapa, BoxRedes, TextSocialMedia, BoxRedesWsp, TextSocialMediaWsp
} from './contactoElements'

function Contacto() {
    return (
        <MainContainer id='contacto'>
            {/* <LeftContacto>
                DERECHA
            </LeftContacto> */}
            <RightContacto>
                <TitleContacto>
                    CONTACTANOS
                </TitleContacto>
                <WrapperContacto>
                    <CardContacto>
                        <SubTitle>
                            <i className="fas fa-map-marker-alt"></i> Ubicanos en:
                        </SubTitle>
                        <TextContacto>
                            <p>
                                {/* <strong>Direccion: </strong> */}
                                ASOC. APTASA. MZ. D - LOTE 15. AVENIDA.BRASIL. Arequipa, Perú.</p>
                        </TextContacto>
                        <ContainerMapa>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d239.2829399656145!2d-71.58812314160532!3d-16.3470931329899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91423611326ad323%3A0x61003dae27e33e6d!2sHidromicser%20SRL!5e0!3m2!1ses-419!2spe!4v1642044838451!5m2!1ses-419!2spe" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                        </ContainerMapa>
                    </CardContacto>
                    <CardContacto>
                        <SubTitle>
                        <i class="fas fa-envelope"></i>Correos:
                        </SubTitle>
                        <TextContacto>
                            <p>innova.metalmec@gmail.com </p>
                        </TextContacto>
                        <TextContacto>
                            <p>ventas@fabricaciones-innova.com </p>
                        </TextContacto>
                        
                        <SubTitle>
                            <i class="fas fa-phone-alt"></i>Telefonos:
                        </SubTitle>
                        <TextContacto>
                            <p><strong>*OFICINA:</strong> 935 465 011</p>
                        </TextContacto>
                        <TextContacto>
                            <p><strong>*VENTAS:</strong> 910 655 018 </p>
                        </TextContacto>
                        <div className='row'>
                        <BoxRedesWsp>
                            <TextSocialMediaWsp href="https://wa.me/+51935465011" target="_blank">
                            <i class="fab fa-whatsapp"></i>  Gerencia
                            </TextSocialMediaWsp>
                        </BoxRedesWsp>
                        <BoxRedesWsp>
                            <TextSocialMediaWsp href="https://wa.me/+51910655018" target="_blank">
                            <i class="fab fa-whatsapp"></i>  Ventas
                            </TextSocialMediaWsp>
                        </BoxRedesWsp>
                        </div>
                        <TextContacto>
                            <p><strong>*ATENCION AL CLIENTE: </strong>935 465 011</p>
                        </TextContacto>
                        <SubTitle>
                            <i class="fas fa-network-wired"></i>Redes:
                        </SubTitle>
                        <BoxRedes>
                            <TextSocialMedia href="https://www.facebook.com/INNOVA-Industria-Metal-Mec%C3%A1nica-EIRL-103037148924054" target="_blank">
                                <i class="fab fa-facebook-square"></i>   FACEBOOK
                            </TextSocialMedia>
                        </BoxRedes>
                        <BoxRedesWsp>
                            <TextSocialMediaWsp href="https://wa.me/+51935465011" target="_blank">
                            <i class="fab fa-whatsapp"></i>  WHATSAPP
                            </TextSocialMediaWsp>
                        </BoxRedesWsp>
                    </CardContacto>
                    {/* <CardContacto>
                        <SubTitle>
                            <i class="fas fa-network-wired"></i>Redes:
                        </SubTitle>
                        <BoxRedes>
                            <TextSocialMedia href="https://www.facebook.com/INNOVA-Industria-Metal-Mec%C3%A1nica-EIRL-103037148924054" target="_blank">
                                <i class="fab fa-facebook-square"></i>   FACEBOOK
                            </TextSocialMedia>
                        </BoxRedes>
                        <BoxRedesWsp>
                            <TextSocialMediaWsp href="https://www.facebook.com/INNOVA-Industria-Metal-Mec%C3%A1nica-EIRL-103037148924054" target="_blank">
                            <i class="fab fa-whatsapp"></i>  WHATSAPP
                            </TextSocialMediaWsp>
                        </BoxRedesWsp>
                    </CardContacto> */}
                </WrapperContacto>
            </RightContacto>

        </MainContainer>
    )
}

export default Contacto
