
import furgon from '../../Media/Img/productos/furgon.png'
import tolva1 from '../../Media/Img/productos/tolva1.png'
import ramfla4 from '../../Media/Img/productos/ramfla4.png'
import plataforma from '../../Media/Img/productos/plataforma.png'
import plataforma1 from '../../Media/Img/productos/plataforma2.png'
import furgonSemi from '../../Media/Img/productos/furgopng.png'
import semiremolque from '../../Media/Img/productos/semiremolque3.png'
import cisterna from '../../Media/Img/productos/cisterna1.png'
import bomba from '../../Media/Img/productos/bomba.png'
import cisternaEmulsion from '../../Media/Img/productos/cisternaEmulsion.png'
export default [
    {
        title: 'TOLVAS',
        description: 'Fabricado para una maxima resistencia.!',
        image: tolva1

    },
    {
        title: 'PLATAFORMA CON BARANDA REBATIBLE',
        description: '',
        image: ramfla4

    },
    {
        title: 'FURGONES',
        description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
        image: furgon

    },
    {
        title: 'FURGÓN SEMIRREMOLQUE',
        description: 'Furgones de 30 TNL, para carga seca..',
        image: furgonSemi

    },
    {
        title: 'CAMA BAJA',
        description: 'Contamos con gran variedad de Cama Bajas, para transporte pesado, liviado y transporte de maquinarias.',
        image: semiremolque

    },
    {
        title: 'CISTERNAS DE EMULSIÓN',
        description: 'Diseñados especialmente para el transporte de anfo a granel para satisfacer las necesidades de construcción y de minería.',
        image: cisternaEmulsion

    },
    {
        title: 'CAMIÓN MIXER',
        description: 'Con los mejores estándares de calidad para el transporte seguro de cemento y otros.',
        image: bomba

    },
  
    {
        title: 'PLATAFORMAS',
        description: '',
        image: plataforma1

    }
]