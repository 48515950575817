import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const DropdownUl = styled.ul`
    width: 200px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    display: ${({active}) => (active ? 'none' : 'block')};

`
export const DropdownLi = styled.li`
    background: #1888ff;
    cursor: pointer;

    &:hover{
        background: #5cabff;
    }
`

export const DropdownItem = styled(Link)`
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
`