import React from 'react'
import { Slides, BoxSlides, BoxImage, SlideImage } from './sliderProductoElements'
function Carousel2({ activeIndex, imageSlider }) {
    return (
        <section>
            {imageSlider.map((slide, index) => (
                <Slides key={index}
                    //    className={index === activeIndex ? " active" : "inactive"}
                    active={index === activeIndex ? (true) : (false)}
                >
                    <BoxSlides >
                        <BoxImage >
                            <SlideImage src={slide.image} alt="" />
                        </BoxImage>

                    </BoxSlides>
                </Slides>
            ))}

        </section>

    )
}

export default Carousel2
