import React from 'react'

import portada1 from '../../../Media/Img/empresa/portada1.jpg'


import {
    MainContainer, ContainerAbout, ContainerTitle, ContainerInfo, TopTile, TitleEmpresa, BotTile,
    WraperHistoria, SubtitleHistoria, TextHistoria, ImgEmpresa, WraperValoresMision, CardValores, CardMision
    , PreParrafo, CardRightEmp, TextCap, TextCapacidades, BoxRoundText, IframeAwesome, ContainerVideo, ImgEmpresaSec
} from './aboutElements'


function About() {
    return (
        <MainContainer id='empresa'>
            <ContainerAbout  >
                <ContainerTitle>
                    <TopTile></TopTile>
                    <TitleEmpresa>
                        EMPRESA
                    </TitleEmpresa>
                    <BotTile>
                    Líder en la fabricación de  carrocerías y semirremolques en el rubro de la industria metal mecánica.
                    </BotTile>
                    <WraperHistoria>
                        <SubtitleHistoria>
                        FABRICACIONES INNOVA
                        </SubtitleHistoria>
                        <TextHistoria>
                        Somos una empresa dedicada a la fabricación de carrocerías y  semirremolques en el rubro de la industria metal mecánica, nuestros productos son innovadores y de la más alta calidad. <br /> <br />
                        
                        INNOVA es una Empresa 100% peruana líder en el sector industrial metalmecánico. 
                        Especialistas en la Fabricación de carrocerías para el transporte de carga pesada y diversa. 
                        Nuestra amplia experiencia nos permite diseñar y elaborar productos con los más altos estándares de calidad.  <br /> <br />
                        Hoy en día INNOVA presta servicios a importantes Empresas del Perú en los sectores de minería, transporte entre otros más, así mismo contamos con el reconocimiento de innumerables clientes y empresas a nivel local y Nacional que nos respaldan.
                        </TextHistoria><br />
                        <TextHistoria>
                            
                        </TextHistoria><br />
                        <TextHistoria>
                            
                        </TextHistoria>
                        <ImgEmpresa src={portada1}>
                        </ImgEmpresa>
                        {/* <ImgEmpresaSec src={ceo}>
                        </ImgEmpresaSec>
                        <ImgEmpresaSec src={ceo4}>
                        </ImgEmpresaSec>
                        <ImgEmpresaSec src={soldadura}>
                        </ImgEmpresaSec> */}
                        <WraperValoresMision>
                            <CardValores>
                                <SubtitleHistoria>
                                <i class="fas fa-star"></i> NUESTROS VALORES
                                </SubtitleHistoria><br />
                                <TextHistoria>
                                    <PreParrafo>Honestidad: </PreParrafo> Una de nuestras cualidades  el cual mantenemos firme en nuestra vida cotidiana, con nuestros clientes y en cada uno de nuestros proyectos,  el cual nos impulsa a cumplir con nuestras obligaciones, así mismo damos todo de nosotros para cumplir lo que ofrecemos.
                                </TextHistoria>   <br />
                                <TextHistoria>
                                    <PreParrafo>Responsabilidad: </PreParrafo>
                                    Consideramos una cualidad y un valor importante en el ser humano es por ello Nuestro compromiso  es cumplir acuerdos y obligaciones  y asumir responsabilidades.
                                </TextHistoria><br />
                                <TextHistoria>
                                    <PreParrafo>Trabajo en Equipo: </PreParrafo>
                                    Todos miramos hacia un solo objetivo, Uno de los pilares importantes en nuestra Empresa es el trabajo en equipo, agradecemos el gran aporte que dan día a día nuestros colaboradores, puesto que el trabajo en equipo nos a permitido ser mas eficientes en nuestro desempeño y así hemos logrado alcanzar muchas metas  y objetivos trazados.
                                </TextHistoria><br />
                                {/* <TextHistoria>
                                    <PreParrafo>Puntualidad: </PreParrafo>
                                    El respeto es fundamental para que una sociedad funcione de forma correcta ya que ayuda en la autoestima y aporta a la coexistencia. La puntualidad demuestra respeto por el tiempo de los demás, demuestra que se es un verdadero profesional, mejora la productividad, demuestra disciplina, es sinónimo de integridad. Se muestra respeto y se exige lo mismo.
                                </TextHistoria><br />
                                <TextHistoria>
                                    <PreParrafo>Trabajo en equipo: </PreParrafo>
                                    Se reducen los errores, se incrementa la motivación y la creatividad, lo difícil se hace más fácil y se hacen las cosas apuntando hacia los mismos objetivos colectivos.
                                </TextHistoria><br />
                                <TextHistoria>
                                    <PreParrafo>Integridad: </PreParrafo>
                                    La integridad se resume en la aplicación de los valores sin excusa alguna donde se hace lo correcto incluso sin que nadie esté viendo. Se predica con el ejemplo en el centro de trabajo, en el hogar y en la sociedad.

                                </TextHistoria> */}
                            </CardValores>
                            <CardMision>
                                <SubtitleHistoria>
                                    <i class="fas fa-award"></i>MISION
                                </SubtitleHistoria><br />
                                <TextHistoria>
                                    {/* <PreParrafo>Gratitud: </PreParrafo>  */}
                                    Ser una empresa confiable brindando a nuestros clientes  productos innovadores  con la mejor tecnologia y materiales, así mismo nuestros clientes aseguren su inversión al elegirnos para así rentabilizar las inversiones y ofrecer seguridad laboral y bienestar a nuestros trabajadores.
                                </TextHistoria>
                                <SubtitleHistoria>
                                    <i class="fas fa-eye"></i> VISION
                                </SubtitleHistoria><br />
                                <TextHistoria>
                                    {/* <PreParrafo>Gratitud: </PreParrafo>  */}
                                    Ser una empresa líder a nivel nacional e internacional, reconocida por nuestros productos inovadores y servicios de primera calidad.


                                </TextHistoria>
                            </CardMision>
                        </WraperValoresMision>
                    </WraperHistoria>

                </ContainerTitle>


                <ContainerInfo>
                    <CardRightEmp>
                        <TextCap>
                            <IframeAwesome className="fas fa-jedi"></IframeAwesome>CAPACIDADES
                        </TextCap>
                        <BoxRoundText>
                            <TextCapacidades>
                                <IframeAwesome className="fas fa-check-double"></IframeAwesome> Personal profesional y técnico altamente calificado.
                            </TextCapacidades>
                        </BoxRoundText>
                        <BoxRoundText>
                            <TextCapacidades>
                                <IframeAwesome className="fas fa-check-double"></IframeAwesome> Soldadores Homologados y Certificados.
                            </TextCapacidades>
                        </BoxRoundText>
                        <BoxRoundText>
                            <TextCapacidades>
                            <IframeAwesome className="fas fa-check-double"></IframeAwesome> Productos de alta calidad, diseño y seguridad
                            </TextCapacidades>
                        </BoxRoundText>
                        <BoxRoundText>
                            <TextCapacidades>
                            <IframeAwesome className="fas fa-check-double"></IframeAwesome> Garantía y servicios post venta
                            </TextCapacidades>
                        </BoxRoundText>
                        <BoxRoundText>
                            <TextCapacidades>
                            <IframeAwesome className="fas fa-check-double"></IframeAwesome> Pensamos en las necesidades del cliente
                            </TextCapacidades>
                        </BoxRoundText>

                        <BoxRoundText>
                            <TextCapacidades>
                            <IframeAwesome className="fas fa-check-double"></IframeAwesome> INNOVA® lo componen empresas socialmente responsables
                            </TextCapacidades>
                        </BoxRoundText>
                        <ContainerVideo>
                        <iframe width="560" height="315" style={{margin:"2rem 0"}} src="https://www.youtube.com/embed/ufC5X5zFz8U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </ContainerVideo>
                        
                        <ContainerVideo>
                        <iframe width="560" height="315" style={{margin:"2rem 0"}} src="https://www.youtube.com/embed/VRori6qhM8g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </ContainerVideo>
                        <ContainerVideo>
                        <iframe width="560" height="315" style={{margin:"2rem 0"}} src="https://www.youtube.com/embed/DNcQrRMQczg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </ContainerVideo>
                    </CardRightEmp>
                </ContainerInfo>

            </ContainerAbout>
        </MainContainer>

    )
}

export default About
