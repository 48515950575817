import styled, { css } from 'styled-components'

export const MainContainer = styled.div`
    width: 100%;
    margin: auto;
`
export const WrapContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    flex-wrap: wrap;
    width: 75%;
    margin: auto;
    padding: 30px;
    @media screen and (max-width: 960px) {
        width: 100%;
    }

`
export const CardLeft= styled.div`
    text-align: center;
    /* justify-content: center;
    align-items: center; */
    width: 65%;
    @media screen and (max-width: 960px) {
        width: 100%;
    }
    /* border: 1px solid black; */
`
export const CardRight= styled.div`
    text-align: center;
    width: 30%;
    /* border: 1px solid black; */
    margin-top: 30px;
    padding-left: 20px;
    /* margin: 20px; */
    @media screen and (max-width: 960px) {
        width: 100%;
    }
`
export const TitleProduct = styled.h3`
    /* font-family: 'Audiowide', cursive; */
    font-family: 'Quantico', sans-serif;
    /* font-family: 'Noto Sans', sans-serif; */
    font-size: 30px;
    text-align: start;
    padding-bottom: 20px;
`
export const Parrafo = styled.p`
    text-align: ${({textAlign}) => (textAlign ? (textAlign):('start'))};
    font-family: 'Noto Sans', sans-serif;
    font-size:14px;
    
`
export const SliderContainer = styled.div`

`
export const Subtitle = styled.h5`
    color: #202020;
    text-align: center;
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    padding-bottom: 20px;
    padding-top:20px;
`
export const ModalDetProducto = styled.div`
  width:100%;
  height:100vh;
  position: relative;
  /* list-style: none;
  text-align: center;
  justify-content: center;
  transition: all 0.5s ease; */
  left:1%;
  z-index:100;
  background: #000;             
  /* ${({ active }) => {
    if (active) {
      return css`
              background: #D1D1D1;             
              z-index: 1;
              left: 0%;
              `
    }
  }} */
`