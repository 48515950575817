import React, { useState } from 'react'
import './productos.css'
import furgonCortinero from '../../../Media/Img/galeriaProductos/furgonCortinero.jpg'
import remolque from '../../../Media/Img/galeriaProductos/remolque2.jpg'
import furgonFrigorifico from '../../../Media/Img/galeriaProductos/furgonFrigorifico.jpg'
import emulsion from '../../../Media/Img/galeriaProductos/emulsion.jpg'
import furgonCargaSeca from '../../../Media/Img/galeriaProductos/furgonCargaSeca1.jpg'
import tanqueCisterna from '../../../Media/Img/galeriaProductos/tanqueCisterna.jpg'
import bombaCementera from '../../../Media/Img/galeriaProductos/bombaCementera.jpg'
import carroceria from '../../../Media/Img/galeriaProductos/carroceria.jpg'
import furgonAcanalado from '../../../Media/Img/galeriaProductos/furgonAcanalado.jpg'
import tolvaEncap from '../../../Media/Img/galeriaProductos/tolvaEncap.jpg'
import rampaHid from '../../../Media/Img/galeriaProductos/rampaHid.jpg'

import ramflaPlataforma from '../../../Media/Img/galeriaProductos/ramflaPlataforma.jpg'

import semiReFrigorifico from '../../../Media/Img/galeriaProductos/semiReFrigorifico.jpg'
import camaBaja from '../../../Media/Img/galeriaProductos/camaBaja.jpg'
import tolvaRoquera from '../../../Media/Img/galeriaProductos/tolvaRoquera1.jpg'
import tolva10 from '../../../Media/Img/galeriaProductos/tolva10.jpg'
import tolvaEncapsulada from '../../../Media/Img/galeriaProductos/tolvaEncapsulada.jpg'
import tolvaCarreta from '../../../Media/Img/galeriaProductos/tolvaCarreta.jpg'
import liviano from '../../../Media/Img/galeriaProductos/liviano.jpg'
import baranda1 from '../../../Media/Img/galeriaProductos/baranda1.jpg'
import cisterna from '../../../Media/Img/galeriaProductos/cisterna.jpg'
///Iconos
import camion from '../../../Media/Icons/camion.png'
// furgonFrigorifico    furgonCargaSeca tolvaEncap
import NavProductos from './NavProductos'
// import {ModalDetProducto} from '../Productos/DetProductos/DetProductosElements'
import Modal from './Modal/Modal'
import { ContenidoModal, WrapModal, WrapContent, WrapModalImg, DetContent, SubtitleContent, DetContainerDesc, SubtitleText } from './Modal/ModalElements'
import { DataToModalProducto } from './Modal/DataToModal'
import SlideProducto from './CarouselProductos/SliderProducto'
function Productos() {
    const [estadoModal, setEstadoModal] = useState(false);
    const [datosModal, setDatosModal] = useState({
        nombre: '',
        path: '',
        contenido: []
    })
    const handleModal = (path) => {
        console.log(path, "PINTANDO EL PATH")
        const prodEncontrado = DataToModalProducto.find((data) => data.path === path)
        console.log(prodEncontrado)
        setDatosModal(prodEncontrado)
        setEstadoModal(!estadoModal)
    }
    return (
        <div id='productos'>

            <p className='title-descubre'>
                Descubre Nuestros
                <div className='titleProductos'>
                    PRODUCTOS
                </div>
            </p>
            <Modal estado={estadoModal}
                cambiarEstado={setEstadoModal}
                title={datosModal.nombre}
                icon={camion}
            >
                <ContenidoModal>
                    <WrapModal>

                        {datosModal.contenido.map((contenido) => {
                            return (
                                <WrapContent>
                                    <SubtitleContent>
                                        <SubtitleText>
                                            <i className="fas fa-angle-right"></i>
                                        </SubtitleText>
                                        <SubtitleText>
                                            {contenido.subtitulo}
                                        </SubtitleText>

                                    </SubtitleContent>

                                    {contenido.descripcion.map((descripcion) => {
                                        return (
                                            <DetContent>
                                                <DetContainerDesc>
                                                    <i className="fas fa-circle"></i>
                                                </DetContainerDesc>
                                                <DetContainerDesc>
                                                    {descripcion}
                                                </DetContainerDesc>

                                            </DetContent>
                                        )
                                    })}

                                </WrapContent>
                            )
                        })}
                    </WrapModal>
                    <WrapModalImg>
                        <SlideProducto imageSlider={datosModal.dataSlide} />
                    </WrapModalImg>
                </ContenidoModal>

            </Modal>
            <NavProductos setEstadoModal={handleModal} />
            <div className='container-productos'>
                <div className='card-producto'>

                    <img className='imagen-producto' src={tolva10} alt="" />
                    <div className='nombre-producto'>
                        Tolva Roquera y Semi Roqueras
                    </div>
                    <div className='descripcion-producto'>
                        Especialista en trabajo pesado!. Fabricado para una maxima resistencia y cumplir con trabajos exigentes como traslados de piedras y rocas.
                    </div>
                </div>
                <div className='card-producto'>

                    <img className='imagen-producto' src={tolvaEncapsulada} alt="" />
                    <div className='nombre-producto'>
                        Tolvas Encapsuladas
                    </div>
                    <div className='descripcion-producto'>
                        Fabricado para el transporte  de mineral y concentrado con materiales de alta resistencia.
                    </div>
                </div>
                <div className='card-producto'>

                    <img className='imagen-producto' src={liviano} alt="" />
                    <div className='nombre-producto'>
                        Barandas Livianas
                    </div>
                    <div className='descripcion-producto'>
                        Especialistas en fabricación de barandas livianas para minería y construcción.
                    </div>
                </div>
                <div className='card-producto'>

                    <img className='imagen-producto' src={furgonCortinero} alt="" />
                    <div className='nombre-producto'>
                        Furgones Cortineros y Contraplacados
                    </div>
                    <div className='descripcion-producto'>
                        Fabricamos furgones de gran resistencia para el transporte de todo tipo de mercancías con accesorios importados que garantizan su seguridad.
                    </div>
                </div>

                <div className='card-producto'>
                    <img className='imagen-producto' src={furgonFrigorifico} alt="" />
                    <div className='nombre-producto'>
                        Furgones Frigoríficos

                    </div>
                    <div className='descripcion-producto'>
                        Con los mejores componentes Inyectados con polibretano de alta calidad  y acabados, tenga por seguro que somos la mejor opción en el mercado con precios competitivos.
                    </div>
                </div>
                {/* <div className='card-producto'>
                    <img className='imagen-producto' src={emulsion} alt="" />
                    <div className='nombre-producto'>
                        Tanques de Emulsión
                    </div>
                    <div className='descripcion-producto'>
                        Especiales para atender necesidades de minería y construcción. Fabricados con los mejores materiales importados para una capacidad de 26 metros cúbicos para 34 toneladas de emulsión.
                    </div>
                </div> */}
                <div className='card-producto'>
                    <img className='imagen-producto' src={furgonCargaSeca} alt="" />
                    <div className='nombre-producto'>
                        Furgones de Carga Seca
                    </div>
                    <div className='descripcion-producto'>
                        El furgón que se adapta al transporte de todo tipo de mercadería. Ofrecemos el mejor furgón del mercado. Máxima resistencia, mejores diseños y acabados.
                    </div>
                </div>
                <div className='card-producto'>
                    <img className='imagen-producto' src={cisterna} alt="" />
                    <div className='nombre-producto'>
                        Tanques Cisterna
                    </div>
                    <div className='descripcion-producto'>
                        Elípticas o circulares para el transporte de agua, combustibles etc. Cumplimos con las normas de OSINERMING.

                    </div>
                </div>
                <div className='card-producto'>
                    <img className='imagen-producto' src={bombaCementera} alt="" />
                    <div className='nombre-producto'>
                        Bombonas Cementeras
                    </div>
                    <div className='descripcion-producto'>
                        Con los mejores materiales de calidad para el transporte seguro de cemento y cal a granel.

                    </div>
                </div>
                <div className='card-producto'>
                    <img className='imagen-producto' src={furgonAcanalado} alt="" />
                    <div className='nombre-producto'>
                        Furgón Acanalado
                    </div>
                    <div className='descripcion-producto'>
                        Carrocería de alta resistencia, ideal para el traslado de diversos tipos de carga, su diseño y los tipos de materiales usados en su fabricación asegura su resistencia a los diferentes tipos de trabajos a los que será sometida.
                    </div>
                </div>
                <div className='card-producto'>
                    <img className='imagen-producto' src={ramflaPlataforma} alt="" />
                    <div className='nombre-producto'>
                        PLATAFORMA CON BARANDA REBATIBLE
                    </div>
                    <div className='descripcion-producto'>
                        Ideal para paletizado y contenedores de 3 ejes suspensión neumático y muelle.

                    </div>
                </div>
                {/* <div className='card-producto'>
                    <img className='imagen-producto' src={rampaHid} alt="" />
                    <div className='nombre-producto'>
                        Rampas de Carga Hidráulicas
                    </div>
                    <div className='descripcion-producto'>
                        Reduzca costos de operación y obtenga mayor rapidez, eficiencia y seguridad en sus operaciones de carga y descarga de mercancías.


                    </div>
                </div> */}
                {/* <div className='card-producto'>
                    <img className='imagen-producto' src={semiReFrigorifico} alt="" />
                    <div className='nombre-producto'>
                        Semirremolques Frigoríficos
                    </div>
                    <div className='descripcion-producto'>

                        Traslada con total confianza productos perecibles o que necesiten temperaturas constantes por largos periodos y trayectos. Utilizamos los mejores materiales y técnicas de fabricación.

                    </div>
                </div> */}
                <div className='card-producto'>
                    <img className='imagen-producto' src={camaBaja} alt="" />
                    <div className='nombre-producto'>
                        Cama Bajas
                    </div>
                    <div className='descripcion-producto'>
                        Las cama bajas son ideales para el transporte de distintos tipos de carga como contenedores, cargas extradimensionadas, maquinaria y equipos pesados, herramientas especializadas entre otros.

                    </div>
                </div>
                <div className='card-producto'>
                    <img className='imagen-producto' src={tolvaCarreta} alt="" />
                    <div className='nombre-producto'>
                        Tolva Carreta con Quinta Rueda
                    </div>
                    <div className='descripcion-producto'>
                    Fabricamos carretas para todo tipo de tolvas con tornamesa a billa.


                    </div>
                </div>

            </div>

        </div>
    )
}

export default Productos
