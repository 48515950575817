import React from 'react'
import './btnWsp.css'
function BtnWsp() {
    return (
        <div>
             <a href="https://wa.me/+51935465011" className='btn-wsp' target={'_blank'}>
             <i className="fab fa-whatsapp"></i>    
            </a>   
        </div>
    )
}

export default BtnWsp
