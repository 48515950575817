import styled, { css } from 'styled-components'

export const SliderContainer = styled.div`
/* background: white; */
width: 500px;
    height: 350px;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow: hidden;
    border: 0.5px transparent gray;
    /* background: blue; */
    /* text-align: center; */
    
    @media screen and (max-width: 960px) {
        height: 330px;
        width: 100vw;
    }
`
export const Slides = styled.div`
 display: flex;
    justify-content: center;
    align-items: center;
    /* height: 400px; */
    width: 100%;
    position: absolute;
    margin-right:100px;
    /* background: red; */
    /* border: 1px solid black; */
    transition: 1s ease-in-out;
    display: ${({active}) => (active ? ('inline-block'):('none'))};
    @media screen and (max-width: 960px) {
        height: 300px;
    }
`

export const BoxSlides = styled.div`
 /* width: 100%; */
    /* height: 400px; */
 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    /* border: 1px solid black; */
    @media screen and (max-width: 960px) {
        height: 300px;
    }
`
export const BoxImage = styled.div`
 
`
export const SlideImage = styled.img`
    width: 500px;
    height: 350px;
    object-fit: cover;
    margin: auto;
    transition: 1s ease-in-out;
    @media screen and (max-width: 960px) {
        width: 100%;
    } 
`

export const Prev = styled.span`
    color: black;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 40px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    opacity: 0.5;
`

export const Next = styled.span`
    color: black;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 40px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    opacity: 0.5;
`