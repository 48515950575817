export const menuItems = [
    {
        title:'Cisternas',
        path: 'lineaCisternas',
        cName: 'dropdown-link'
    } ,
    {
        title:'Cama Bajas',
        path: 'lineaCamaBaja',
        cName: 'dropdown-link'
    } , 
    
    {
        title:'Tolvas',
        path: 'lineaTolvas',
        cName: 'dropdown-link'
    } ,   
    {
        title:'Bombonas',
        path: 'lineaBombonas',
        cName: 'dropdown-link'
    } ,  
    {
        title:'Furgones',
        path: 'lineaFurgones',
        cName: 'dropdown-link'
    } , 
    {
        title:'Carretas',
        path: 'lineaCarretas',
        cName: 'dropdown-link'
    } , 
    {
        title:'Plataformas con Baranda Rebatible',
        path: 'lineaRamflas',
        cName: 'dropdown-link'
    } ,
    {
        title:'Ramfla Plataforma Semiremolque',
        path: 'lineaPlataforma',
        cName: 'dropdown-link'
    } 
]

export const menuItemsCarrocerias = [
    {
        title:'Furgones Estandar',
        path: '/furgonesEstandar',
        cName: 'dropdown-link'
    } ,   
    {
        title:'Furgones Frigorificos',
        path: '/furgonesFrigorificos',
        cName: 'dropdown-link'
    } ,  
    {
        title:'Furgones Contraplacados',
        path: '/furgonesContraplacados',
        cName: 'dropdown-link'
    } ,  
   
    {
        title:'Barandas Metalicas',
        path: '/barandasMetalicas',
        cName: 'dropdown-link'
    } ,  
    {
        title:'Furgones Para Gas',
        path: '/furgonesGas',
        cName: 'dropdown-link'
    } ,
    {
        title:'Furgones Especiales',
        path: '/furgonesEspeciales',
        cName: 'dropdown-link'
    } ,  
    {
        title:'Furgones Cortineros',
        path: '/furgonesCortineros',
        cName: 'dropdown-link'
    } 
]
export const menuItemsTrabajosIndustriales = [
    {
        title:'TRABAJOS INDUSTRIALES',
        path: 'lineaTrabajoIndustrial',
        cName: 'dropdown-link'
    } ,  
  
]