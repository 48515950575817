import React, {useState, useEffect} from 'react'
import Carousel2 from './Carousel2'
// import imageSlider from './imageSlider'
import Arrows from './Arrows'
import Dots from './Dots'
import "./slider.css"

// const len = imageSlider.length - 1;
function Slider({imageSlider}) {
    
    const len = imageSlider.length - 1;
    const [activeIndex, setActiveIndex] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
            // console.log(DataSlider)
        }, 5000)
        return ()=> clearInterval(interval);
    }, [activeIndex])
    return (
        <div className='slider-container'>
            
            <Carousel2 activeIndex={activeIndex} imageSlider={imageSlider} />
            <Arrows 
                prevSlide={() => setActiveIndex(activeIndex < 1 ? len: activeIndex -1)} 
                nextSlide={() => setActiveIndex(activeIndex === len ? 0: activeIndex +1)}
            />
            <Dots 
                activeIndex={activeIndex} 
                imageSlider={imageSlider} 
                onclick={(activeIndex) => setActiveIndex(activeIndex)}/>
        </div>
    )
}

export default Slider
