import React from 'react'

function Carousel2({ activeIndex, imageSlider }) {
    return (
        <section>
            {imageSlider.map((slide, index) => (
                <div key={index} className={index === activeIndex ? "slides active" : "inactive"}>
                    <div className='boxSlides'>
                        <div className='boxImage'>
                            <img className='slide-image' src={slide.image} alt="" />
                        </div>
                        <div className='boxDescription'>
                            <div>
                            <h2 className='slide-title'>{slide.title}</h2>
                            <h3 className='slide-text'>{slide.description}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </section>

    )
}

export default Carousel2
