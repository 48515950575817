import styled, { css } from 'styled-components'

export const MainContainer = styled.div`
    width: 100%;
    border-top: 0.7px solid #E1E1E1;
    background: #fff;
    /* box-shadow: 0px 0px 5px 6px rgb(102, 102, 102); */
`
export const ContainerAbout = styled.div`
    /* height: 1880px; */
    width: 90%;
    /* padding: 1rem; */
    margin: 2rem auto ;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: center;
    @media screen and (max-width: 960px) {
        width: 100%;
  }

    /* border: 1px solid black; */
`
export const ContainerTitle = styled.div`
  width: 60%;
  padding: 2rem;
  /* height: 600px; */
  /* border: 1px solid black; */
  
  color: rgb(194, 15, 15);
  /* -webkit-text-stroke: 0.1px #fff; */
  font-weight: bolder;
  /* font-size: 50px; */
  @media screen and (max-width: 960px) {
    width: 100%;
  }
` 
export const ContainerInfo = styled.div`
    width: 40%;
    display: flex;
    align-items: flex-start;
    margin-top: 80px;
    justify-content: start;
  /* border: 1px solid black; */
  @media screen and (max-width: 960px) {
    width: 100%;
    margin-top: 10px;
  }
`
export const TitleEmpresa = styled.div`
    color: rgb(194, 15, 15);
    
    font-size: 4rem;
    text-align: center;
    font-family: 'Audiowide', cursive;
    @media screen and (max-width: 960px) {
        font-size:30px;
    }
`

export const TopTile = styled.div`
    margin-left: 15%;
    color: #3B3B3B;
    font-size: 20px;
    text-align: start;
    font-family: 'Quicksand', sans-serif;
    font-weight: 100;
    @media screen and (max-width: 960px) {
        /* width: 400px; */
        margin-left: 0%;
        text-align: start;
    }
`
export const BotTile = styled.div`
    /* margin-left: 15%; */
    color:  #181818;
    font-size: 18px;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    /* width: 800px; */
    /* font-weight: 200; */
    /* width: 100%; */

    @media screen and (max-width: 960px) {
        /* width: 400px; */
        margin-left: 0%;
        text-align: end;
    }
`

export const WraperHistoria = styled.div`
    /* width: 350px; */
    font-family: 'Quicksand', sans-serif;
    text-align: start;
    margin-top: 30px;
    text-align: center;
    /* height */
`
export const SubtitleHistoria = styled.h4`
text-align: start;
    margin-top: 40px;
    color: #181818;
    font-size: 28px;
`
export const TextHistoria = styled.p`
margin-top: 5px;
color: #181818;
    font-size: 16px;
    text-align: justify;
`
export const ImgEmpresa = styled.img`
margin-top: 40px;
    object-fit: cover;
    width:100%;
    text-align: center;
    box-shadow: 0px 0px 5px 2px rgb(92, 92, 102);
    /* justify-content: center; */
    @media screen and (max-width: 960px) {
        width:100%;
    }
`
export const ImgEmpresaSec = styled.img`
margin: 2px;
    object-fit: cover;
    width:31%;
    height: 400px;
    object-fit: cover;
    text-align: center;
    box-shadow: 0px 0px 5px 2px rgb(92, 92, 102);
    /* justify-content: center; */
    @media screen and (max-width: 960px) {
        width:100%;
    }
`
export const WraperValoresMision = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width:100%;
`

export const CardValores = styled.div`
    width: 50%;
    /* padding: 1rem; */
    @media screen and (max-width: 960px) {
        width:100%;
    }
`
export const CardMision = styled.div`
width: 50%;
padding: 1rem;
@media screen and (max-width: 960px) {
        width:100%;
        padding: 0rem;
    }
`
export const PreParrafo = styled.strong`
    font-size: 18px;
    color: black;
`
export const CardRightEmp = styled.div`
    width: 80%;
    font-family: 'Quicksand', sans-serif;    
    margin-top: 30px;
    text-align: center;
    margin: auto;
    @media screen and (max-width: 960px) {
        width:100%;
        
    }
`
export const TextCap = styled.h3`
    font-size: 40px;
    font-weight: 800;
    color: black;
    text-align: center;
`
export const TextCapacidades = styled.p`
    text-align: start;
    font-size: 16px;
    font-weight: 400;
    /* border: 2px solid rgb(194, 15, 15); */
    border-radius: 10px;
    margin: 5px;
    color: white;
    /* width: 80%auto; */
`
export const BoxRoundText = styled.div`
border: 1px solid gray;
    border-radius:10px;
    margin: 0.5rem;
    background-color: #CB9C0D;
`

export const IframeAwesome =  styled.i`
    font-size: 40px;
    color: rgb(194, 15, 15);
`

export const ContainerVideo =  styled.div`
    padding-top: 100px;
    /* border: 1px solid black; */
    iframe{
        width:100%;
        height:315px;
    margin:auto;
    padding:0;
    }
    @media screen and (max-width: 960px) {
        padding-top: 20px;
        width:100%;
                iframe{
                width: 100%;
                height:215px;
            margin:auto;
            padding:0;
            }
    }
    
`