import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar'
import Carousel from './Components/Carousel/Carousel'
import Productos from './Components/Pages/Productos/Productos'
import Home from './Components/Pages/Home/Home'
import About from './Components/Pages/About/About'
import Contacto from './Components/Pages/Contacto/Contacto'
import Footer from './Components/Pages/Footer/Footer'
import BtnWsp from './Components/BtnWsp/BtnWsp'
import DetProductos from './Components/Pages/Productos/DetProductos/DetProductos'
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home/>} />
        {/* <Route exact path="/productos" element={<Productos/>} /> */}
        <Route exact path="/detProductos/:id" id={'tanque'} element={<DetProductos/>} />
        {/* <Route exact path="/empresa" element={<About/>} /> */}
        
      </Routes>
      <Footer/>
      <BtnWsp/>
    </Router>
  );
}

export default App;
