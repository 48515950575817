import furgon from '../../../../Media/Img/productos/furgon.png'
import semiremolque from '../../../../Media/Img/productos/semiremolque3.png'
import cisterna1 from '../../../../Media/Img/detproductos/cisternas/tanqueCisterna1.jpg'
import cisterna2 from '../../../../Media/Img/detproductos/cisternas/tanqueCisterna3.jpg'
import cisterna3 from '../../../../Media/Img/detproductos/cisternas/tanqueCisterna5.jpg'
import cisterna4 from '../../../../Media/Img/detproductos/cisternas/tanqueCisterna4.jpg'
import camaBaja1 from '../../../../Media/Img/detproductos/camaBaja/camaBaja1.png'
import camaBaja2 from '../../../../Media/Img/detproductos/camaBaja/camaBaja2.jpg'
import camaBaja3 from '../../../../Media/Img/detproductos/camaBaja/camaBaja3.jpg'
import tolva1 from '../../../../Media/Img/detproductos/tolvas/tolva1.jpg'
import tolva2 from '../../../../Media/Img/detproductos/tolvas/tolva2.jpg'
import tolva3 from '../../../../Media/Img/detproductos/tolvas/tolva3.jpg'
import tolva7 from '../../../../Media/Img/detproductos/tolvas/tolva7.jpg'
import bom1 from '../../../../Media/Img/detproductos/bombonas/bom1.jpg'
import bom2 from '../../../../Media/Img/detproductos/bombonas/bom2.jpg'
import furgo1 from '../../../../Media/Img/detproductos/furgones/furgo1.jpg'
import furgo2 from '../../../../Media/Img/detproductos/furgones/furgo2.jpg'
import furgo3 from '../../../../Media/Img/detproductos/furgones/furgo3.jpg'
import ramfla1 from '../../../../Media/Img/detproductos/ramfla/ramfla1.jpeg'
import ramfla2 from '../../../../Media/Img/detproductos/ramfla/ramfla2.jpg'
import ramfla3 from '../../../../Media/Img/detproductos/ramfla/ramfla3.jpg'
import grano1 from '../../../../Media/Img/detproductos/tgrano/grano1.jpg'
import grano2 from '../../../../Media/Img/detproductos/tgrano/grano2.jpg'
import grano3 from '../../../../Media/Img/detproductos/tgrano/grano3.jpg'
import frigo1 from '../../../../Media/Img/detproductos/frigo/frigo1.jpg'
import frigo2 from '../../../../Media/Img/detproductos/frigo/frigo2.jpg'
import frigo3 from '../../../../Media/Img/detproductos/frigo/frigo3.jpg'
import contraplacado1 from '../../../../Media/Img/detproductos/contraplacados/contraplacado1.jpg'
import baranda1 from '../../../../Media/Img/detproductos/baranda/baranda1.jpg'
import baranda2 from '../../../../Media/Img/detproductos/baranda/baranda2.jpg'
import baranda3 from '../../../../Media/Img/detproductos/baranda/baranda3.jpg'
import gas1 from '../../../../Media/Img/detproductos/gas/gas1.jpg'
import gas2 from '../../../../Media/Img/detproductos/gas/gas2.jpg'
import gas3 from '../../../../Media/Img/detproductos/gas/gas3.jpg'
// import bom3 from '../../../../Media/Img/detproductos/bombonas/bom3.jpg'
import bomba from '../../../../Media/Img/productos/bomba.png'
import carroceria from '../../../../Media/Img/productos/carroceria.png'

export const DefaultDataProducto = {
    nombreProducto: '',
    pathParam: 'tanquesCisterna',
    subtitleCaracteristicas: 'Principales características del Tanque Cisterna ATLAS AC-K1:',
    caracteristicas: 'Principales Características:',
    detalleCaracteristicas: [
       'Un camión cisterna, conocido también como camión de gas o camión de combustible, diseñado para transportar cargas líquidas o gases a través de las carreteras de forma segura.'
    ],
    descripcionCaracteristicas: [
        '- Diseños y planos exclusivos aprobados por nuestro staff de ingenieros.',
        '- Suspensión neumática WATSON & CHALIN de 30,000 lbs RL 317  de procedencia americana.',
        '- Proceso de soldadura GMAW / SMAW / FCAW.',
        '- Ejes ZB de 71.5 de trocha con cámaras de aire tipo 30 (DIAF - 8")',
        '- Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros  longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
        '- Distribución adecuada de tapas y rompe olas de secciones elípticas reforzados con perfiles verticales espaciados de acuerdo a normas DOT.',
        '- Plato King Pin JOST de procedencia brasilera.',
        '- Válvulas y sumideros de asistencia mecánica.',
        '- Válvula interlock, acoples de descarga, sensor óptico, válvula recuperadora de gases y adaptadores de procedencia americana.',
        '- Accesorios de marca Civacon.',
        '- Bocinas de retroceso, carteles de señalización reglamentarios.',
        '- Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
        '- Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
    ],
    descripcion: [
        'Nuestros tanques semirremolques cisterna de la serie ATLAS elípticas o circulares, son diseñados para el transporte de agua, combustible, asfalto y similares. Cumplimos rigurosamente con las normas establecidas por OSINERGMIN.',
        'Consideramos muy importante los procesos de fabricación y pintado en este tipo de producto, por ello, nuestros procesos de pintura son permanentes durante todo el tiempo de fabricación, aplicando bases anticorrosivas de alta calidad en todas las piezas usando modernos procedimientos de aplicación de epoxicado certificado y granallado en la superficie interior de los tanques, asegurando un mayor tiempo de vida y bajos costos de mantenimiento de la unidad.',
        'Le brindamos a su empresa flotas altamente especializadas con procesos de fabricación modernos y solución integral de venta, post- venta y mantenimiento preventivo.',
        'Grupo Pinto será su socio estratégico comprometido en brindarle solución total a sus operaciones de logística.'

    ],
    dataSlide: [
        // {

        //     title: 'FURGONES',
        //     description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
        //     image: furgon

        // },
        // {
        //     title: 'SEMIREMOLQUES',
        //     description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
        //     image: semiremolque

        // },
        // {
        //     title: 'CISTERNA',
        //     description: 'Especiales para atender necesidades de minería y construcción.',
        //     image: cisterna

        // },
        // {
        //     title: 'BOMBONAS CEMENTERAS',
        //     description: 'Con los mejores estándares de calidad para el transporte seguro de cemento y otros.',
        //     image: bomba

        // },
        // {
        //     title: 'CARROCERIAS',
        //     description: 'Fabricamos todo tipo de Carrocerias con maderas de gran calidad.',
        //     image: carroceria
        // }
    ],
    adicional1: 'ADICIONALES / OPCIONALES:',
    introAdicional1: '',
    descripcionAdicional1: [
        ' Dossier de calidad.',
        ' Accionamiento de rampas con sistema hidráulico.',
        ' Sistema de frenos tipo ABS. '
    ],
}

export const DataDetProducto = [
    {
        
            nombreProducto: 'CISTERNAS',
            descripcion: [
                'Un camión cisterna, conocido también como camión de gas o camión de combustible, diseñado para transportar cargas líquidas o gases a través de las carreteras de forma segura.'
    
            ],
            pathParam: 'cisterna',
            subtitleCaracteristicas: 'Principales características del Tanque Cisterna ATLAS AC-K1:',
            caracteristicas: 'Principales Características:',
            detalleCaracteristicas: [
                ' Diseños y planos exclusivos aprobados por nuestro staff de ingenieros.',
                ' Suspensión neumática WATSON & CHALIN de 30,000 lbs RL 317  de procedencia americana.',
                ' Proceso de soldadura GMAW / SMAW / FCAW.',
                ' Ejes ZB de 71.5 de trocha con cámaras de aire tipo 30 (DIAF - 8")',
                ' Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros  longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                ' Distribución adecuada de tapas y rompe olas de secciones elípticas reforzados con perfiles verticales espaciados de acuerdo a normas DOT.',
                ' Plato King Pin JOST de procedencia brasilera.',
                ' Válvulas y sumideros de asistencia mecánica.',
                ' Válvula interlock, acoples de descarga, sensor óptico, válvula recuperadora de gases y adaptadores de procedencia americana.',
                ' Accesorios de marca Civacon.',
                ' Bocinas de retroceso, carteles de señalización reglamentarios.',
                ' Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                ' Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
            ],
         
            
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: cisterna1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: cisterna2
    
                },
                {
                    title: 'CISTERNA',
                    description: 'Especiales para atender necesidades de minería y construcción.',
                    image: cisterna3
    
                },
                {
                    title: 'BOMBONAS CEMENTERAS',
                    description: 'Con los mejores estándares de calidad para el transporte seguro de cemento y otros.',
                    image: cisterna4
    
                }
            ],
            adicional1: 'ADICIONALES / OPCIONALES:',
            descripcionAdicional1: [
                '- Dossier de calidad.',
                '- Accionamiento de rampas con sistema hidráulico.',
                '- Sistema de frenos tipo ABS. '
            ]
        },
        {
        
            nombreProducto: 'CAMAS BAJAS',
            descripcion: [
               'El Transporte de Carga Lowboy, conocido también como remolque de plataforma de cama baja, sirve para transportar objetos pesados, herramientas, maquinaria o contenedores, ya que su capacidad de carga va desde las 10 hasta cientos de toneladas.'
    
            ],
            pathParam: 'camaBajas',
            subtitleCaracteristicas: 'Principales características del Tanque Cisterna ATLAS AC-K1:',
            caracteristicas: 'Principales Características:',
            detalleCaracteristicas: [
                ' Suspensiones de muelle tipo TRIDEM de 09 hojas con 4" de ancho.',
                ' Rampas de accionamiento manual fabricadas con canales U estándar americano.',
                ' Frenos de aire comprimido de acción directa tipo BENDIX de doble línea con válvulas de seguridad.',
                ' Sistema eléctrico reglamentario.',
                ' Kit de herramientas básicas Stanley. ',
                ' Servicio de Post - Venta con chequeos cuatrimestrales gratuitos.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: camaBaja1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: camaBaja2
    
                },
                {
                    title: 'CISTERNA',
                    description: 'Especiales para atender necesidades de minería y construcción.',
                    image: camaBaja3
    
                }               
            ],
            adicional1: 'ADICIONALES / OPCIONALES:',
            introAdicional1: '',
            descripcionAdicional1: [
                ' Dossier de calidad.',
                ' Accionamiento de rampas con sistema hidráulico.',
                ' Sistema de frenos tipo ABS. '
            ],
        },
        {
        
            nombreProducto: 'TOLVAS',
            descripcion: [
           'Tractocamión de volteo que tiene una caja de descarga ubicada en la parte trasera la cual se utiliza para transportar materiales como arena, tierra, escombros, entre otros. La caja, conocida con el nombre de tolva, funciona a través de un mecanismo hidráulico que permite su elevación.'
            ],
            pathParam: 'tolvas',
            subtitleCaracteristicas: 'Principales características del Tanque Cisterna ATLAS AC-K1:',
            caracteristicas: 'Principales características Encapsulado modelo ATLAS MA-K1:',
            detalleCaracteristicas: [
                'Capacidad de carga 34 toneladas.',
                'Proceso de soldadura 100% GMAW -FCAW.',
                'Recubrimiento interior en plancha HARDOX 450 x 3/16".',
                'Suspensión neumática WATSON & CHALIN de 30,000 lbs RL 317  de procedencia americana.',
                'Ejes ZB de 71.5 de trocha con cámaras de aire tipo 30 (DIAF - 8")',
                'Pistón principal HYVA de 05 cuerpos o similar.',
                'Plato King Pin JOST de procedencia brasilera.',
                'Tratamiento de pintura mecánica con tratamiento epoxicado y granallado maximizando el tiempo de vida.',
                'Tapa de fibra de vidrio activada por pistones auxiliares neumáticos.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: tolva1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: tolva2
    
                },
                {
                    title: 'CISTERNA',
                    description: 'Especiales para atender necesidades de minería y construcción.',
                    image: tolva3
    
                }     ,
                {
                    title: 'TOLVA ENCAPSULADA',
                    description: 'Diseñados para el transporte de minerales zinc, concentrado de cobre, carbón, abono entre otros.',
                    image: tolva7
    
                }           
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'BOMBONAS',
            descripcion: [
           'Camión Bombona o Camión Hormigonera se utiliza para transportar a largas distancias el hormigón elaborado en una central de hormigonado, sin que durante el trayecto el material se deteriore o merme su calidad.'
            ],
            pathParam: 'bombonas',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales características',
            detalleCaracteristicas: [
                'Suspensión neumática WATSON & CHALIN de procedencia americana dando hasta 10% adicional de capacidad de carga útil.',
                '03 ejes de procedencia americana con 01 eje con kit de levante.',
                'Accesorios importados de primera calidad.',
                'Diseño y fabricación de acuerdo a los procedimientos establecidos en el Manual de Aseguramiento de la Calidad de Grupo Pinto.',
                'Iluminación que cunple con el reglamento nacional de tránsito vehicular.',
                'Carta de garantía con chequeos trimestrales gratuitos.',
                'Servicio Post Venta insuperable y de respuesta rápida.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: bom1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: bom2
    
                }               
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'FURGONES',
            descripcion: [
            'Vehículo de más de cuatro ruedas que tiene en la parte posterior una plataforma o un cajón cerrado para transportar cargas pesadas o de gran tamaño.'   
    
            ],
            pathParam: 'furgones',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características HARDWORK CS-A1',
            detalleCaracteristicas: [
                'Fabricado con adhesivos estructurales de alta calidad y proceso MIG-MAG al 100%.',
                'Bases primer Sherwin Williams.',
                'Fabricación SIN REMACHES evitando mantenimientos costosos y acabados perfectos.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Luces de posicionamiento reglamentarias LED y de salón. ',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Precios competitivos.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales GRATIS.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: furgo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: furgo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: furgo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'CARRETAS',
            descripcion: [
            'Vehículo con dos, cuatro o más ruedas, armazón que sostiene la carga y varas que permiten enganchar el tiro. En el caso específico de las carretas, son carros estrechos y de gran longitud, más bajos que otros, que tienen un plano que se extiende hasta la lanza donde se realiza la sujeción del yugo.'
    
            ],
            pathParam: 'carretas',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características HARDWORK CS-A1',
            detalleCaracteristicas: [
                'Fabricado con adhesivos estructurales de alta calidad y proceso MIG-MAG al 100%.',
                'Bases primer Sherwin Williams.',
                'Fabricación SIN REMACHES evitando mantenimientos costosos y acabados perfectos.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Luces de posicionamiento reglamentarias LED y de salón. ',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Precios competitivos.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales GRATIS.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: furgo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: furgo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: furgo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'RAMFLA METALERA',
            descripcion: [
               ''
                
    
            ],
            pathParam: 'ramflaMetalera',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características REBATIBLE HARDWORK BR-A1',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Puertas rebatibles desmontables para mayor versatilidad.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales GRATIS.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: ramfla1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: ramfla2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: ramfla3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'TOLVA GRANELERA',
            descripcion: [
               'Utilizadas para el transporte de productos agroindustriales que requieren protección contra el medio ambiente, como café, o maíz o trigo. Poseen compuertas en la parte superior e inferior que facilitan la carga y descarga de productos a granel.'
                
    
            ],
            pathParam: 'tolvaGranelera',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características REBATIBLE HARDWORK BR-A1',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Puertas rebatibles desmontables para mayor versatilidad.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales GRATIS.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: grano1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: grano2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: grano3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'FURGONES ESTANDAR',
            descripcion: [
                'Vehículo de más de cuatro ruedas que tiene en la parte posterior una plataforma o un cajón cerrado para transportar cargas pesadas o de gran tamaño.'    
    
            ],
            pathParam: 'furgonesEstandar',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características HARDWORK CS-A1',
            detalleCaracteristicas: [
                'Fabricado con adhesivos estructurales de alta calidad y proceso MIG-MAG al 100%.',
                'Bases primer Sherwin Williams.',
                'Fabricación SIN REMACHES evitando mantenimientos costosos y acabados perfectos.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Luces de posicionamiento reglamentarias LED y de salón. ',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Precios competitivos.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales GRATIS.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: furgo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: furgo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: furgo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'FURGONES FRIGORIFICOS',
            descripcion: [
           'Vehículo aislado térmicamente y dotado, además, de una instalación (grupo compresor frigorífico) capaz de mantener una temperatura interior constante por debajo de cierto nivel, con el fin de conservar mercancías perecederas.'
            ],
            pathParam: 'furgonesFrigorificos',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características HARDWORK FI-A1 de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con adhesivos estructurales de alta calidad y proceso MIG-MAG y TIG al 100%.',
                'Bases anticorrosivas etching primer Sherwin Williams en todo el proceso de fabricación por pintores residentes, garantizando un producto de alta calidad.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Fabricación SIN REMACHES garantizando la fuga de frío y evitando mantenimientos costosos.',
                'Aislante de poliuretano inyectado de alta densidad 40KG/m3.',
                'Forro interior de inox calidad 304.',
                'Equipo de frío con control desde cabina.',
                'Cortinas de flujo para evitar fuga de temperatura en carga y descarga.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Precios competitivos.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales GRATIS.',
                'Servicio Post Venta'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: frigo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'FURGONES CONTRAPLACADOS',
            descripcion: [
                'Furgones con aislamiento en techo y barandas que permite la conservación de productos perecederos y logran mantener la temperatura interior controlada. Ideales para el transporte de productos que requieren cierto control de temperatura en trayectos  menores de 6 horas aislando la temperatura interior de la temperatura exterior.'
                
            ],
            pathParam: 'furgonesContraplacados',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características HARDWORK FC-A1  de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con adhesivos estructurales de alta calidad y proceso MIG-MAG y TIG al 100%.',
                'Bases anticorrosivas etching primer Sherwin Williams en todo el proceso de fabricación por pintores residentes, garantizando un producto de alta calidad.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Fabricación SIN REMACHES garantizando la fuga de frío y evitando mantenimientos costosos.',
                'Aislante de poliuretano inyectado de alta densidad 40KG/m3.',
                'Forro interior de inox calidad 304.',
                'Equipo de frío con control desde cabina.',
                'Cortinas de flujo para evitar fuga de temperatura en carga y descarga.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Precios competitivos.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales GRATIS.',
                'Servicio Post Venta'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: contraplacado1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: contraplacado1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: contraplacado1
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'BARANDAS METALICAS',
            descripcion: [
               ''
            ],
            pathParam: 'barandasMetalicas',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características TELERA HARDWORK BT-A1 de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Goles removibles para comodidad de carga y descarga.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: baranda1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: baranda2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: baranda3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
       
        {
        
            nombreProducto: 'Furgones Gas',
            descripcion: [
               'Utilizado para transportar gases licuados ya que ocupan menor volumen de esta manera, facilitando su transporte y aumentando la cantidad que puede ser transportada, siendo necesario aumentar la presión interior.'
            ],
            pathParam: 'furgonesGas',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características TELERA HARDWORK BT-A1 de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Goles removibles para comodidad de carga y descarga.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: gas1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: gas2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: gas3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },        
        {
        
            nombreProducto: 'Furgones Especiales',
            descripcion: [
                ''
            ],
            pathParam: 'furgonesEspeciales',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características TELERA HARDWORK BT-A1 de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Goles removibles para comodidad de carga y descarga.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: frigo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'Furgones Cortineros',
            descripcion: [
               ''
            ],
            pathParam: 'furgonesCortineros',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características TELERA HARDWORK BT-A1 de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Goles removibles para comodidad de carga y descarga.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: frigo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'Fabricacion Montaje de Estructuras Metalicas',
            descripcion: [
              'Nuestras estructuras se utilizan como complemento de los productos que se elaboran tales como: cubiertas, campamentos, cámaras de refrigeración, etc.'              
            ],
            pathParam: 'fabricacionMetal',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características TELERA HARDWORK BT-A1 de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Goles removibles para comodidad de carga y descarga.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: frigo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'Servicios Industriales',
            descripcion: [
               'Abarcamos máquinas industriales y herramientas proveedoras de partes a las demás industrias metálicas, siendo el metal y las aleaciones de hierro su insumo básico para la utilización en bienes de capital productivo.'
            ],
            pathParam: 'serviciosIndustriales',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características TELERA HARDWORK BT-A1 de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Goles removibles para comodidad de carga y descarga.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: frigo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        },
        {
        
            nombreProducto: 'Ejes Locos',
            descripcion: [
               'Nuestros ejes locos permiten que el camión pueda maniobrarse con mayor facilidad. El eje loco delante del eje motriz de altura ajustable reduce el desgaste de los neumáticos y el consumo de combustible. Manejo de carga excelente y conducción cómoda y estable.'
            ],
            pathParam: 'ejesLocos',
            subtitleCaracteristicas: 'Principales características',
            caracteristicas: 'Principales Características TELERA HARDWORK BT-A1 de alta resistencia:',
            detalleCaracteristicas: [
                'Fabricado con perfiles y planchas A-36 y proceso MIG-MAG al 100%.',
                'Bases anticorrosivas de alta calidad en todo el proceso de pintado garantizando bajo costo de mantenimiento.',
                'Goles removibles para comodidad de carga y descarga.',
                'Acabado con esmaltes acrílicos de alta calidad.',
                'Cumplimiento con el tiempo de entrega.',
                'Acabados insuperables.',
                'Producto económico y liviano.',
                'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos.',
                'Servicio Post Venta.'
            ],
            
          
            dataSlide: [
                {
    
                    title: 'FURGONES',
                    description: 'Con gran capacidad, se adapta al transporte de todo tipo de mercaderia.',
                    image: frigo1
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo2
    
                },
                {
                    title: 'SEMIREMOLQUES',
                    description: 'Contamos con una gran variedad de Remolques y Semiremolques.',
                    image: frigo3
    
                }                
            ],
            adicional1: '',
            introAdicional1: '',
            descripcionAdicional1: [
                // 'Capacidad de carga 15 MTS3.',
                // 'Proceso de soldadura GMAW - FCAW.',
                // 'Estructura base en plancha estructural ASTM A-36 con travesaños empalmados con escuadras de refuerzo a los miembros longitudinales para contrarestar los esfuerzos alternantes y fatiga de material.',
                // 'Piso de acero estructural ASTM A-36 de 4.0 mm o Hardox a pedido del cliente.',
                // 'Compuerta posterior con hoja batiente con apertura de 180 grados.',
                // 'Cilindro hidráulico HYVA o similar de 03 cuerpos.',
                // 'Bombas de engranajes de 1000 PSI con caudal máximo de 1800 RPM: 30 G.P.M.',
                // 'Diseño y fabricación de acuerdo a procedimientos establecidos en el Manual de Aseguramiento de Calidad de Grupo Pinto.',
                // 'Incluye carta de garantía con cronograma de chequeos trimestrales gratuitos. '
            ],
        }
]