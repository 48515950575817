import React, { useState } from 'react'
import { menuItems } from './MenuItems'

import { Link } from 'react-router-dom'
import './dropDown.css'
function Dropdown({setEstadoModal}) {
    const [click, setClick] = useState(false)
    const [clickDet, setClickDet] = useState(false)
    const handleClick = () => setClick(!click)

    return (
        <>
            <div onClick={handleClick}
                className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                {menuItems.map((item, index) => {
                    return (
                        <li key={index}>
                            <a className={item.cName}
                                // to={`/detProductos${item.path}`}
                                onClick={() => setEstadoModal(item.path)}>
                                {item.title}
                            </a>
                        </li>
                    )
                })}
            </div>
            
        </>
    )
}

export default Dropdown
