import React, { useState } from 'react'
import Dropdown from './Dropdown'
import DropdownCarrocerias from './DropdownCarrocerias'
import DDTrabajosIndustriales from './DDTrabajosIndustriales'
import {Link, useNavigate } from 'react-router-dom'
import './navProductos.css'

function DropDownProd({setEstadoModal}) {
    const redirectTo = useNavigate();
    const [click, setClick] = useState(false)
    const [dropdown, setDropdown] = useState(false);
    const [dropdownCarrocerias, setDropdownCarrocerias] = useState(false);
    const [dropdownTrabajosInd, setDropdownTrabajosInd] = useState(false);

    const handleClick = () => setClick(!click);
    const openDropdown = () => setDropdown(!dropdown)
    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(true)
        }
    }
    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false)
        }
    }

    const openDropdownCarrocerias = () => setDropdownCarrocerias(!dropdownCarrocerias)
    const onMouseEnterCarrocerias = () => {
        if (window.innerWidth < 960) {
            setDropdownCarrocerias(false);
        } else {
            setDropdownCarrocerias(true)
        }
    }
    const onMouseLeaveCarrocerias = () => {
        if (window.innerWidth < 960) {
            setDropdownCarrocerias(false);
        } else {
            setDropdownCarrocerias(false)
        }
    }

    const openDropdownTrabInd = () => setDropdownTrabajosInd(!dropdownTrabajosInd)
    const onMouseEnterTrabInd = () => {
        if (window.innerWidth < 960) {
            setDropdownTrabajosInd(false);
        } else {
            setDropdownTrabajosInd(true)
        }
    }
    const onMouseLeaveTrabInd = () => {
        if (window.innerWidth < 960) {
            setDropdownTrabajosInd(false);
        } else {
            setDropdownTrabajosInd(false)
        }
    }
   

    return (
        <nav className='navbar'>
            <ul className={click ? 'nav-menu active': 'nav-menu'}>
                <li className='nav-item'
                    onMouseEnter={onMouseEnter}                    
                    onMouseLeave={onMouseLeave}
                    onClick={openDropdown}
                    >                        
                    <a className='nav-links'>SEMIREMOLQUES<i class="fas fa-sort-down"></i></a>                    
                    {dropdown && <Dropdown setEstadoModal={setEstadoModal} />}               
                    
                </li>
                
                {/* <li className='nav-item'
                    onMouseEnter={onMouseEnterCarrocerias}                    
                    onMouseLeave={onMouseLeaveCarrocerias}
                    onClick={openDropdownCarrocerias}
                    >                        
                    <a className='nav-links'>CARROCERIAS<i class="fas fa-sort-down"></i></a>                    
                    {dropdownCarrocerias && <DropdownCarrocerias />}               
                    
                </li> */}
              
                <li className='nav-item'
                    onMouseEnter={onMouseEnterTrabInd}                    
                    onMouseLeave={onMouseLeaveTrabInd}
                    onClick={openDropdownTrabInd}
                    >                        
                    <a className='nav-links'>TRABAJOS INDUSTRIALES<i className="fas fa-sort-down"></i></a>                    
                    {dropdownTrabajosInd && <DDTrabajosIndustriales setEstadoModal={setEstadoModal} />}               
                    
                </li>
            </ul>
        </nav>
    )
}

export default DropDownProd
