import React, {useState} from 'react'
import {Link, useNavigate } from 'react-router-dom'
import './dropDown.css'
import { menuItemsTrabajosIndustriales } from "./MenuItems";
function DDCarrocerias({setEstadoModal}) {
    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)
    const onClickLink = () =>{
        setClick(false)
        // navigate(`/`)
    }
    return (
        <>
             <div onClick={handleClick}
                className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                    {menuItemsTrabajosIndustriales.map((item, index)  => {
                        return (
                            <li key={index}>
                                <a
                                className={item.cName} 

                                onClick={() => setEstadoModal(item.path)}
                                >
                                    {item.title}
                                </a>
                            </li>
                        )
                    })}
                </div>  
        </>
    )
}

export default DDCarrocerias
