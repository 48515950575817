import React from 'react'
import {Prev, Next} from './sliderProductoElements'
function Arrows({prevSlide, nextSlide}) {
    return (
        <div className='arrows'>
            <Prev className='prev' onClick={prevSlide}>&#10094;</Prev>
            <Next className='next' onClick={nextSlide}>&#10095;</Next>
        </div>
    )
}

export default Arrows
