import styled from 'styled-components'

export const MainContainer = styled.div`
    width: 90%;
    border-top: 0.7px solid #E1E1E1;
    background: #fff;
    /* box-shadow: 0px 0px 5px 6px rgb(102, 102, 102); */
    display: grid;
    grid-gap: 10px;
    margin: 20px auto;
    align-items: center;
    grid-auto-columns: minmax(auto, 1fr);
    grid-template-areas: 'right right right';
    @media screen and (max-width: 960px) {
        
        width: 100%;
    }
`


export const RightContacto = styled.div`
margin: 1rem;
    /* width: 70%; */
    /* border: 1px solid black; */
    grid-area: right;
    
    
`
export const LeftContacto = styled.div`
    /* width: 30%; */
    margin: 1rem;
    border: 1px solid black;
    font-family: 'Quicksand', sans-serif;
    grid-area: left;
    /* grid-column: 1/2; */
`

export const TitleContacto = styled.div`
    color: rgb(194, 15, 15);    
    font-size: 2rem;
    text-align: center;
    font-family: 'Audiowide', cursive;
    font-weight: bold;
    margin: 30px 0px;
    @media screen and (max-width: 960px) {
        font-size:2.3rem;
    }
`
export const WrapperContacto = styled.div`
    width: 70%;
    display: flex;
    margin: auto;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    /* padding-bottom: 2rem; */
    @media screen and (max-width: 960px) {
        width: 100%;
    }
`
export const SubTitle = styled.div`
    font-size: 1.5rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
`
export const CardContacto = styled.div`
    /* width: 20%; */
    margin: 20px auto;
    @media screen and (max-width: 960px) {
        width:100%;
    }
`
export const TextContacto = styled.div`
    font-size: 20px;
    margin: 30px auto;
    font-weight: normal;
    font-family: 'Quicksand', sans-serif;
`
export const ContainerMapa = styled.div`
width: 100%;
box-shadow: 0px 0px 10px -1px rgb(92, 92, 102);
`
export const BoxRedes = styled.div`
width: 50%;
border: 1px solid #3b5998;
    border-radius:10px;
    margin: 2rem 0rem;
    background-color: #3b5998;
    color: white;
    cursor: pointer;
    text-align: center;
    &:hover{
        background-color: white;
    color:  #3b5998;
    }
    @media screen and (max-width: 960px) {
        width:100%;
    }
`
export const TextSocialMedia = styled.a`
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    padding: 20px;
    font-weight: normal;
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
    color: white;
    line-height: 2.4rem;
    outline: none;
    
    &:hover{
        
    color:  #3b5998;
    }
    @media screen and (max-width: 960px) {
        width:100%;
    }
`
export const BoxRedesWsp = styled.div`
width: 50%;
border: 1px solid #00bb2d ;
    border-radius:10px;
    margin: 2rem 0rem;
    background-color: #00bb2d ;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover{
        background-color: white;
    color:  #00bb2d ;
    }
    @media screen and (max-width: 960px) {
        width:100%;
    }
`
export const TextSocialMediaWsp = styled.a`
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    padding: 20px;
    font-weight: normal;
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
    color: white;
    line-height: 2.4rem;
    outline: none;
    &:hover{
        
    color:  #00bb2d ;
    }
`