import React from 'react'
// import {animateScroll as scroll} from 'react-scroll'
import {FaFacebook, FaInstagram, FaYoutube, FaWhatsapp, FaTwitter} from 'react-icons/fa'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinksItems,
    FooterLinksTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons, SocialIconLink
} from './FooterElements'

function Footer() {
    // const toggleHome = () =>{
    //     scroll.scrollToTop();
    // }
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinksItems>
                            <FooterLinksTitle>Nosotros </FooterLinksTitle>                                
                                <FooterLink to="/about">Empresa</FooterLink>
                                
                                                         
                        </FooterLinksItems>
                    
                        <FooterLinksItems>
                            <FooterLinksTitle>Contactanos </FooterLinksTitle>                                
                                <FooterLink to="/">Contactos</FooterLink>
                               
                               
                                
                        </FooterLinksItems>
                    </FooterLinksWrapper>
                        <FooterLinksWrapper>
                        
                    
                        <FooterLinksItems>
                            <FooterLinksTitle>Redes Sociales </FooterLinksTitle>                                
                                <FooterLink to="https://wa.me/+51935465011">Whatsapp</FooterLink>
                                <FooterLink to="https://www.facebook.com/INNOVA-Industria-Metal-Mec%C3%A1nica-EIRL-103037148924054">Facebook</FooterLink>
                               
                               
                                
                        </FooterLinksItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to="/" >
                            INNOVA
                        </SocialLogo>
                        <WebsiteRights>Innova {new Date().getFullYear()} 
                        Todos los derechos reservados</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="https://www.facebook.com/INNOVA-Industria-Metal-Mec%C3%A1nica-EIRL-103037148924054" target="_blank" aria-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>                            
                            <SocialIconLink href="https://wa.me/+51935465011" target="_blan" aria-label="Whattsapp">
                                <FaWhatsapp/>
                            </SocialIconLink>
                           
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
