import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SlideProducto from '../CarouselProductos/SliderProducto'
import { DataDetProducto, DefaultDataProducto } from './DataCarousel'
import NavProductos from '../../Productos/NavProductos'
import {
    MainContainer, WrapContainer, CardLeft, CardRight,
    TitleProduct, Parrafo, Subtitle
} from './DetProductosElements'
function DetProductos() {
    const [DataProducto, setDataProducto] = useState(DefaultDataProducto)
    const { id } = useParams();
    console.log(id, "antes del Get")
    const getDataProducto = () => {
        console.log('Entro al Get', id, DataDetProducto )
        const resBusquedaParam = DataDetProducto.find((detProducto) => detProducto.pathParam === id)

        console.log(resBusquedaParam, "RESULTADO BUSQUEDA");
        setDataProducto(resBusquedaParam)
    }
    useEffect(() => {
        console.log(id, "PARAMS")
        // console.log(DataDetProducto)
        if (DataProducto) {
            getDataProducto();
        }

    }, [id])
    return (
        <>
            {DataProducto ? (<>
                <NavProductos />
                <MainContainer>
                    <WrapContainer>
                        <CardLeft>
                            <TitleProduct>
                                {DataProducto.nombreProducto}
                            </TitleProduct>
                            {
                                DataProducto.descripcion.map((parrafo) => {
                                    return (
                                        <>
                                            <Parrafo>
                                                {parrafo}
                                            </Parrafo><br />
                                        </>
                                    )
                                })
                            }
                            <SlideProducto imageSlider={DataProducto.dataSlide} />
                            <Subtitle>
                                {DataProducto.caracteristicas}
                            </Subtitle>
                            {
                                DataProducto.detalleCaracteristicas.map((detCaracteristicas) => {
                                    return (
                                        <>
                                            <Parrafo>
                                                <i className="fas fa-caret-right"></i>{detCaracteristicas}
                                            </Parrafo><br />
                                        </>
                                    )
                                })
                            }
                        </CardLeft>
                        <CardRight>
                            <Subtitle>
                                INFORMACIÓN DE CONTACTO.
                            </Subtitle>
                            <Parrafo textAlign={'center'}>
                                <strong> Direccion:</strong> ASOC. APTASA. MZ. D - LOTE 15. AVENIDA.BRASIL.s Arequipa, Perú.
                            </Parrafo><br />
                            <Parrafo textAlign={'center'}>
                                <strong> Atencion al Cliente:</strong> 935 465 011
                            </Parrafo><br />

                            <Parrafo textAlign={'center'}>
                                <strong> Email:</strong> innova.metalmec@gmail.com

                            </Parrafo><br />

                            <Subtitle>
                                {DataProducto.adicional1}
                            </Subtitle>
                            <Parrafo textAlign={'justify'}>
                                {DataProducto.introAdicional1}
                            </Parrafo><br />
                            

                            
                            {DataProducto.descripcionAdicional1.map((desAdicional)=>{
                              return (<> <Parrafo textAlign={'center'}>
                                <i className="fas fa-star"></i> {desAdicional}

                            </Parrafo><br />
                            </>)
                            })}
                     

                        </CardRight>
                    </WrapContainer>
                </MainContainer></>) : (
                <>

                </>

            )}

        </>
    )
}

export default DetProductos
