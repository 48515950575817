import React from 'react'
import { Overlay, ContenedorModal, EncabezadoModal, BotonCerrar } from './ModalElements'
function Modal({ children, estado, cambiarEstado, title, icon }) {
  return (
    <>
      {estado &&
        <Overlay>
          <ContenedorModal>
            <EncabezadoModal>
              <img src={icon} alt="" /><h3>{title}</h3>
            </EncabezadoModal>
            <BotonCerrar onClick={() => cambiarEstado()}><i class="fas fa-times"></i></BotonCerrar>
            {children}
          </ContenedorModal>
        </Overlay>
      }

    </>
  )
}

export default Modal